import axios, { AxiosInstance } from 'axios';
import store from '@/store';
import { loadLanguageAsync } from '@/i18n';
import { csrfInterceptor } from '@/services/CsrfInterceptor';

class AuthService {
  private static readonly TokenThresholdSecs = 5 * 60;
  private static readonly TokenIntervalMS = 60 * 1000; // 1 minute

  private readonly client: AxiosInstance;
  constructor () {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_SMA_BASE_URL,
      withCredentials: true
    });
    this.client.interceptors.request.use(csrfInterceptor);
  }

  login (redirect: string): void {
    window.location.assign(`${process.env.VUE_APP_SMA_BASE_URL}/v1/auth/login?redirect=${redirect}`);
  }

  async isAuthenticated () {
    const { isLoggedIn, isFsiEnabled, isMfaStepUpRequired } = store.getters;

    if (isLoggedIn) {
      // MFA is required and FSI is not enabled, so redirect user to complete MFA login flow
      return !(isMfaStepUpRequired && !isFsiEnabled);
    }

    async function readData (data: any) {
      if (!data) {
        return;
      }

      store.commit('setFsiEnabled', data.fsiEnabled);
      store.commit('setUserCompanyWhitelisted', data.userCompanyWhitelisted);
      store.commit('setMfaStepUpRequired', data.mfaStepUpRequired);

      await loadLanguageAsync(data.language);

      return { isFsiEnabled: data.fsiEnabled, isMfaStepUpRequired: data.mfaStepUpRequired };
    }

    return this.client.get('v1/auth/userinfo')
      .then(async resp => await readData(resp.data))
      .then(userInfo => {
        if (!userInfo) {
          return false;
        }

        // MFA is required and FSI is not enabled, so redirect user to complete MFA login flow
        return !(userInfo.isMfaStepUpRequired && !userInfo.isFsiEnabled);
      })
      .then(isUserLoggedIn => {
        store.commit('setLoggedIn', isUserLoggedIn);
        return isUserLoggedIn;
      })
      .catch(() => {
        store.commit('setLoggedIn', false);
        store.commit('setFsiEnabled', false);
        store.commit('setMfaStepUpRequired', false);
        store.commit('setUserCompanyWhitelisted', false);
        return false;
      });
  }

  async refreshToken () {
    return this.client.get('v1/auth/refresh')
      .then(resp => {
        if (resp.status !== 200) {
          throw Error('failed to fetch refresh token');
        }
        return resp;
      })
      .then(resp => resp.data)
      .catch(() => {
        this.logout();
      });
  }

  logout () {
    store.commit('setLoggedIn', false);
    store.commit('setFsiEnabled', false);
    store.commit('setMfaStepUpRequired', false);
    store.commit('setUserCompanyWhitelisted', false);
    window.location.assign(`${process.env.IAM_AUTH_BASE_URL}/v1/logout?appId=6f4abebc-2a7e-4a4c-b0ff-2b6c2c365de1&policy=B2C_1A_FPG_SIGNIN_FEDERATED_MFA_V1`);
  }

  startRefreshJob (): void {
    setInterval(async () => await this.refreshTokenJob(), AuthService.TokenIntervalMS);
  }

  private async refreshTokenJob () {
    try {
      if (this.isTokenExpired()) {
        const { expiresAt } = await this.refreshToken();
        store.commit('tokenExpiration', expiresAt);
      }
    } catch (ex) {
      console.warn(ex);
    }
  }

  private isTokenExpired (): boolean {
    const currentEpochTime = Date.now() / 1000;
    const expirationEpochTime = store.state.tokenExpiration - AuthService.TokenThresholdSecs;

    return currentEpochTime >= expirationEpochTime;
  }
}

export default new AuthService();
