<template>
  <div class="d-flex flex-column" v-if="isLoggedIn">
    <fcs-page-header-pro
      v-if="!$route.meta.hideNavigation"
      :headerTitle="$t('vehicleSecurity.titles.header')"
      :intra-app-links="intraAppLinks"
      :productLabel="$t('navLinks.vehicles')"
      :signOutText="$t('logout')"
      :signOutClick="logout">
      <template slot="authorized-apps">
        <a class="fcs-drawer-links-hover" :href="FFMURL">
          {{ $i18n.t('global.userHelpSettings.fleetMarketplace') }}
        </a>
      </template>
    </fcs-page-header-pro>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import authService from '@/services/AuthService';
import { i18n } from '@/i18n';

export default {
  mounted () {
    authService.startRefreshJob();
  },
  data () {
    return {
      userHelpSettings: [
        {
          hrefLink: process.env.VUE_APP_FFM_URL,
          linkName: i18n.t('global.userHelpSettings.fleetMarketplace')
        }
      ],
      FFMURL: process.env.VUE_APP_FFM_URL
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isFsiEnabled']),
    ...mapState('featureFlags', ['featureFlags']),
    intraAppLinks () {
      const isFsiEnabled = false;
      function show (name) {
        if (name === 'Home' || name === 'Alerts') {
          return true;
        }
        if ((name === 'Inhibit' || name === 'Vehicles') && isFsiEnabled) {
          return true;
        }
        return false;
      }

      return this.$router.options.routes
        .filter(p => p.meta && p.meta.isIntraAppLink && show(p.name))
        .map(p => {
          return {
            hrefLink: p.path,
            linkName: this.$t(`navLinks.${p.name.toLowerCase()}`)
          };
        });
    }
  },
  methods: {
    logout: () => authService.logout()
  }
};
</script>
