/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import VehicleList from '@/components/VehicleList.vue';
import Vehicles from '@/fleetstartinhibit/components/Vehicles.vue';
import CreateSecurityAlert from '@/components/CreateSecurityAlert.vue';
import CreateInconsistentLocationAlert from '@/components/CreateInconsistentLocationAlert.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    alias: '/vehicle-security',
    component: VehicleList,
    meta: { isIntraAppLink: true },
    props: true
  },
  // {
  //   path: '/inhibit',
  //   name: 'Inhibit',
  //   component: Inhibit,
  //   meta: { isIntraAppLink: true },
  //   props: true
  // },
  // {
  //   name: 'ScheduleInhibit',
  //   path: '/schedule-inhibit',
  //   meta: { isIntraAppLink: false },
  //   component: ScheduleInhibit
  // },
  {
    name: 'Vehicles',
    path: '/vehicles',
    meta: { isIntraAppLink: true },
    component: Vehicles
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: () => import(/* webpackChunkName: "Notifications" */ '@/components/Alerts.vue'),
    meta: { isIntraAppLink: true }
  },
  {
    name: 'CreateSecurityAlert',
    path: '/create-security-alert',
    meta: { hideNavigation: true },
    component: CreateSecurityAlert
  },
  {
    name: 'CreateInconsistentLocationAlert',
    path: '/create-inconsistent-location-alert',
    meta: { hideNavigation: true },
    component: CreateInconsistentLocationAlert
  },
  {
    name: 'CreateInclineAlert',
    path: '/create-incline-alert',
    component: () => import('@/components/CreateInclineAlert.vue'),
    meta: { hideNavigation: true }
  },
  {
    name: 'CreateImpactAlert',
    path: '/create-impact-alert',
    component: () => import('@/components/CreateImpactAlert.vue'),
    meta: { hideNavigation: true }
  },
  {
    name: 'Edit',
    path: '/edit/:type',
    component: () => import('@/components/common/Edit.vue'),
    meta: { hideNavigation: true },
    props: true
  },
  {
    name: 'EditVehicles',
    path: '/editVehicles/:scheduleName',
    component: () => import('@/fleetstartinhibit/components/EditVehicles.vue'),
    meta: { isIntraAppLink: false }
  }
];

export default routes;
